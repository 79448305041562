// import _ from "lodash";
import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  products: [],
  product: {},
  category: {},
  brands: [],
  categories: [],
  groups: [],
  tags: [],
  archived: [],
};

// getters
const getters = {
  all: (state) => state.products,
  categories: (state) => state.categories,
  get: (state) => state.product,
  category: (state) => state.category,
  brands: (state) => state.brands,
  groups: (state) => state.groups,
  sizes: (state) => state.sizes,
};

// actions
const actions = {
  loadProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/products`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadProductsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadSizes({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/sizes`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadSizesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadTags({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/tags`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTagsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/products/${data.productId}`,
        method: "GET",
      })
        .then((resp) => {
          const product = resp.data.product;
          commit("loadProductSuccess", { product });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  loadCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/categories/${
          data.categoryId
        }`,
        method: "GET",
      })
        .then((resp) => {
          const category = resp.data.category;
          commit("loadCategorySuccess", { category });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  updateGalleryOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/products/${
          data.productId
        }/display-order`,
        method: "POST",
        data: {
          displayOrder: data.displayOrder,
        },
      })
        .then((resp) => {
          commit("updateGalleryOrderSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveProduct({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/eow/products/${data.productId}`
      : `${getApiUrl(data.appId)}/module/eow/products`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveProductSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTagsForProduct({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/eow/products/${
      data.productId
    }/load-tags`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          productId: data.productId,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("loadTagsForProductSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveProductTags({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/eow/products/${
      data.productId
    }/add-tags`;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          const product = resp.data.product;
          commit("saveProductTagsSuccess", {
            product,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveOption({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/eow/options`;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveOptionSuccess", {
            ...resp.data,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteProductImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/images/${data.imageId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductImageSuccess", { imageId: data.imageId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteProductOption({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/products/${
          data.productId
        }/attribute/${data.attributeId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductOptionSuccess", { productId: data.productId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveProductRelated({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/eow/products/${
      data.productId
    }/add-related`;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveProductRelatedSuccess", {
            ...resp.data,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/products/${data.productId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductSuccess", { productId: data.productId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  loadBrands({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/brands`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBrandsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/categories`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadCategoriesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/eow/categories/${data.categoryId}`
      : `${getApiUrl(data.appId)}/module/eow/categories`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
            parents: data.fields.parents,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCategorySuccess", {
            categoryId: data.categoryId,
            parentId: data.parentId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveOptionProduct({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/eow/products/${
          data.productId
        }/options/${data.optionId}`
      : `${getApiUrl(data.appId)}/module/eow/products/${
          data.productId
        }/options`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveOptionProduct", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  updateBrandsDisplayOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/brands/display-order`,
        method: "POST",
        data: {
          displayOrder: data.displayOrder,
        },
      })
        .then((resp) => {
          commit("updateBrandsDisplayOrderSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  updateSizesDisplayOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/sizes/display-order`,
        method: "POST",
        data: {
          displayOrder: data.displayOrder,
          groupId: data.groupId,
        },
      })
        .then((resp) => {
          commit("updateSizesDisplayOrderSucess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  updateCategoryDisplayOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/categories/display-order`,
        method: "POST",
        data: {
          displayOrder: data.displayOrder,
          parentId: data.parentId,
        },
      })
        .then((resp) => {
          commit("updateCategoryDisplayOrderSucess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteBrand({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/brands/${data.brandId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteBrandSuccess", { brandId: data.brandId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/sizes-group/${data.groupId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteGroupSuccess", { groupId: data.groupId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteSize({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/sizes/${data.sizeId}`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteSizeSuccess", {
            sizeId: data.sizeId,
            ...resp.data,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveBrand({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/eow/brands/${data.brandId}`
      : `${getApiUrl(data.appId)}/module/eow/brands`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveBrandSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveSize({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/eow/sizes/${data.sizeId}`
      : `${getApiUrl(data.appId)}/module/eow/sizes`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveSizeSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveSizeGroup({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/eow/sizes-group/${data.groupId}`
      : `${getApiUrl(data.appId)}/module/eow/sizes-group`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveSizeGroupSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadProductsSuccess(state, data) {
    state.products = data.products;
    state.archived = data.archived;
  },
  loadBrandsSuccess(state, data) {
    state.brands = data.brands;
  },
  loadProductSuccess(state, data) {
    state.product = data.product;
  },
  loadCategorySuccess(state, data) {
    state.category = data.category;
  },

  loadSizesSuccess(state, data) {
    state.groups = data.groups;
  },

  uploadImageSuccess() {},
  removeImageSuccess() {},
  saveProductSuccess(state, data) {
    if (data.isEditing) {
      const index = state.products.findIndex((p) => p.id === data.product.id);
      state.products.splice(index, 1, data.product);
    } else {
      state.products.push(data.product);
    }
  },
  loadTagsForProductSuccess(state, data) {
    state.tags = data.tags;
  },
  saveProductTagsSuccess(state, data) {
    const index = state.products.findIndex((p) => p.id === data.product.id);
    state.products.splice(index, 1, data.product);
  },

  saveBrandSuccess(state, data) {
    if (data.isEditing) {
      const index = state.brands.findIndex((p) => p.id === data.brand.id);
      state.brands.splice(index, 1, data.brand);
    } else {
      state.brands.push(data.brand);
    }
  },

  deleteProductSuccess(state, data) {
    const index = state.products.findIndex((p) => p.id === data.productId);
    state.archived.push(state.products[index]);
    state.products.splice(index, 1);
  },
  loadTagsSuccess(state, data) {
    state.tags = data.tags;
  },
  loadCategoriesSuccess(state, data) {
    state.categories = data.categories;
  },

  saveCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.categories.findIndex(
        (c) => c.id === data.category.id
      );
      state.category = data.category;
      state.categories.splice(index, 1, data.category);
    } else {
      if (data.category.is_parent) {
        state.categories.push(data.category);
      } else {
        const indexParent = state.categories.findIndex(
          (c) => c.id === data.parents[0]
        );
        state.categories[indexParent].children.push(data.category);
      }
    }
  },
  deleteCategorySuccess(state, data) {
    if (data.parentId) {
      const index = state.categories.findIndex((c) => c.id === data.parentId);

      const indexCategory = state.categories[index].children.findIndex(
        (p) => p.id === data.categoryId
      );

      state.categories[index].children.splice(indexCategory, 1);
    } else {
      const index = state.categories.findIndex((c) => c.id === data.categoryId);
      state.categories.splice(index, 1);
    }
  },

  deleteBrandSuccess(state, data) {
    const index = state.brands.findIndex((c) => c.id === data.brandId);
    state.brands.splice(index, 1);
  },

  updateBrandsDisplayOrderSuccess(state, data) {
    state.brands = data.brands;
  },

  updateSizesDisplayOrderSucess(state, data) {
    state.groups = data.groups;
  },

  updateCategoryDisplayOrderSucess(state, data) {
    state.categories = data.categories;
  },

  deleteSizeSuccess(state, data) {
    const index = state.groups.findIndex((c) => c.id === data.group_id);

    const indexSize = state.groups[index].sizes.findIndex(
      (p) => p.id === data.sizeId
    );

    state.groups[index].sizes.splice(indexSize, 1);
  },

  deleteGroupSuccess(state, data) {
    const index = state.groups.findIndex((c) => c.id === data.groupId);
    state.groups.splice(index, 1);
  },

  deleteProductImageSuccess(state, data) {
    if (data.isMain) {
      state.product.main_image = null;
    } else {
      const index = state.product.gallery.findIndex(
        (c) => c.id === data.imageId
      );
      state.product.gallery.splice(index, 1);
    }
  },

  saveOptionSuccess(state, data) {
    state.options.push(data.option);
  },

  saveProductRelatedSuccess(state, data) {
    state.product = data.related;
  },

  deleteProductOptionSuccess(state, data) {
    const index = state.products.findIndex((p) => p.id === data.productId);
    state.archived.push(state.products[index]);

    state.product.attributes.splice(index, 1);
  },

  updateGalleryOrderSuccess(state, data) {
    state.product.gallery = data.images;
  },

  saveSizeGroupSuccess(state, data) {
    if (data.isEditing) {
      const index = state.groups.findIndex(
        (p) => p.id === parseInt(data.group.id)
      );
      state.groups.splice(index, 1, data.group);
    } else {
      state.groups.push(data.group);
    }
  },

  saveSizeSuccess(state, data) {
    const index = state.groups.findIndex(
      (c) => c.id === parseInt(data.size.group_id)
    );

    if (data.isEditing) {
      const indexSize = state.groups[index].sizes.findIndex(
        (p) => p.id === parseInt(data.size.id)
      );
      state.groups[index].sizes.splice(indexSize, 1, data.size);
    } else {
      state.groups[index].sizes.push(data.size);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
