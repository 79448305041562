import "@mdi/font/css/materialdesignicons.css";
import "./assets/style.css";
import "element-ui/lib/theme-chalk/index.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import * as Utils from "./utils";

import App from "@/views/App.vue";
//import { Cascader } from "element-ui";
import IdleVue from "idle-vue";
import LockedValue from "@/views/components/sensitive/LockedValue";
import ToggleButton from "vue-js-toggle-button";
import Treeselect from "@riophae/vue-treeselect";
import VLockedTextField from "@/views/components/sensitive/VLockedTextField";
import VTooltip from "v-tooltip";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import moment from "moment-timezone";
import router from "@/router";
import store from "@/stores/";
import vuetify from "@/plugins/vuetify";

// import the styles

//import ElementUI from "element-ui";

// import VueOffline from "vue-offline";

Vue.config.productionTip = false;
moment.tz.setDefault("Europe/London");

Vue.router = router;

Vue.use(VTooltip);

//Vue.use(ElementUI);

//Vue.use(Cascader);

Vue.use(ToggleButton);

Vue.use(IdleVue, {
  store,
  idleTime: 300000,
  startAtIdle: false,
});

// Vue.use(VueOffline);

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = `${process.env.VUE_APP_ACCOUNTS_URL}/api`;
Vue.axios.defaults.withCredentials = true;
Vue.axios.interceptors.response.use(
  (response) => {
    const token = localStorage.getItem("token");
    if (response.headers.authorization && token) {
      const responseToken = response.headers.authorization;

      if (token !== responseToken) {
        localStorage.setItem("token", responseToken);
        Vue.axios.defaults.headers.common = {
          Authorization: "Bearer " + responseToken,
        };
      }
    }

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("forceLogout").then(() => router.push("/login"));
    } else if (error.response.status === 500) {
      store.commit("showGlobalSnackbar", {
        open: true,
        text: "An Error has occurred. If this error persists, please contact Vital Hike.",
        color: "error",
      });
      store.commit("showHideLoader", false);
    }

    return Promise.reject(error);
  }
);

const token = localStorage.getItem("token");
if (token) {
  Vue.axios.defaults.headers.common = {
    Authorization: "Bearer " + token,
  };
}

Vue.mixin({
  methods: {
    getCookie() {
      return Utils.getCookie();
    },
    setTitle: function (title) {
      Utils.setTitle(title);
    },
    getColor: function () {
      return store.getters.brand.color;
    },
    setBugherd: function () {
      const environment = process.env.VUE_APP_STAGE;
      if (environment !== "local") {
        let bugherdApiKey = process.env.VUE_APP_BUGHERD_API_KEY;

        if (
          store.getters.brand.bugherd_api_key !== "" &&
          store.getters.brand.bugherd_api_key !== null
        ) {
          bugherdApiKey = store.getters.brand.bugherd_api_key;
        }

        let bugherdScript = document.createElement("script");
        bugherdScript.type = "text/javascript";
        bugherdScript.src =
          "https://www.bugherd.com/sidebarv2.js?apikey=" + bugherdApiKey;
        document.head.appendChild(bugherdScript);
      }
    },
    pluralString: function (string) {
      if (string === null || typeof string === "undefined") return;

      const hasTrailingS =
        string.toLowerCase().charAt(string.length - 1) === "s" ? true : false;

      if (hasTrailingS) {
        return string.concat("'");
      }

      return string.concat("'s");
    },
    formatDate: function (date) {
      if (!date) return null;

      if (date.search("-") == -1) return date;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    formatDateYearMonthDay: function (date) {
      if (!date) return null;

      if (date.search("-") == -1) return date;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },

    formatPrice(value) {
      return parseFloat(value).toFixed(2);
    },

    formatDateAsString: function (date) {
      if (!date) return null;
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const dateBrokenDown = date.split("-");
      const d = new Date(date);

      const dayNumber = function (day) {
        if (day > 3 && day < 21) return day.concat("th");

        switch (day % 10) {
          case 1:
            return day.concat("st");
          case 2:
            return day.concat("nd");
          case 3:
            return day.concat("rd");
          default:
            return day.concat("th");
        }
      };

      return `${days[d.getDay()]} ${dayNumber(dateBrokenDown[2])} ${
        months[d.getMonth()]
      } ${dateBrokenDown[0]}`;
    },

    changeTimeZone: function (date) {
      if (typeof date === "string") {
        return new Date(
          new Date(date).toLocaleString("gb-GB", { timeZone: "Europe/London" })
        );
      }

      return new Date(
        date.toLocaleString("gb-GB", { timeZone: "Europe/London" })
      );
    },

    formatDateTime: function (datetime, withTime = false) {
      // const dt = this.changeTimeZone(new Date(datetime));

      const dt = new Date(datetime);

      let time = "";

      if (withTime) {
        const hours = this.padNumber(dt.getHours());
        const minutes = this.padNumber(dt.getMinutes());

        time = " " + hours + ":" + minutes;
      }

      const day = this.padNumber(dt.getDate());
      const month = this.padNumber(dt.getMonth() + 1);

      return day + "/" + month + "/" + dt.getFullYear() + time;
    },
    padNumber(number, size = 2) {
      let n = String(number);
      while (n.length < size) {
        n = "0" + n;
      }

      return n;
    },
    ucfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    hasPermission(permission, appId = null) {
      if (appId) {
        return Utils.hasPermission(permission, appId);
      }

      return false;
    },
    age(dob) {
      var b = moment(dob, "YYYY-MM-DD HH:mm:ss");
      var a = moment(new Date(), "YYYY-MM-DD HH:mm:ss");

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");

      return years + " years " + months + " months " + days + " days";
    },

    capitalize: function (data) {
      let capitalized = [];
      if (data) {
        data
          .replace("-", " ")
          .split(" ")
          .forEach((word) => {
            capitalized.push(
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            );
          });
      }

      return capitalized.join(" ");
    },
  },

  filters: {
    capitalize: function (data) {
      let capitalized = [];
      data
        .replace("-", " ")
        .split(" ")
        .forEach((word) => {
          capitalized.push(
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          );
        });

      return capitalized.join(" ");
    },
  },
});

Vue.component("v-locked-text-field", VLockedTextField);
Vue.component("locked-value", LockedValue);
Vue.component("treeselect", Treeselect);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
